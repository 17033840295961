let kodamaConfig ={
  "local":{
    apiUrl:"/api",
    gsmEnv:"sit",
    analyticsEnv:"dev",
    hideNPTitleSupportedPlatforms: false,
    hideIntegrationTypes: false
  },
  "q1-np":{
    apiUrl:"/api",
    gsmEnv:"sit",
    analyticsEnv:"qa",
    hideNPTitleSupportedPlatforms: false,
    hideIntegrationTypes: false
  },
  "e1-np":{
    apiUrl:"/api",
    gsmEnv:"sit",
    analyticsEnv:"qa",
    hideNPTitleSupportedPlatforms: false,
    hideIntegrationTypes: false
  },
  "p1-spint":{
    apiUrl:"/api",
    gsmEnv:"prod",
    analyticsEnv:"prod",
    hideNPTitleSupportedPlatforms: false,
    hideIntegrationTypes: false
  },
  "mgmt":{
    apiUrl:"https://kodama.e1-np.tools.playstation.net/",
    gsmEnv:"local"
  }
};

let deployEnv = window.deployEnv || 'local';
deployEnv = deployEnv.trim();

let config = {
  kodama: kodamaConfig[deployEnv],
  KA_PATH_KEY: "kaPath",
  MENU_ITEM_KEY: "menu_item_id",
  APP_ID_KEY: "app_id",
  CONTEXT_VALUE: "context_value",
  KODAMA_APP_PATH_MESSAGE: "KODAMA_APP_PATH_CHANGE",
  KODAMA_APP_BREADCRUMB_MESSAGE: "KODAMA_APP_BREADCRUMB_CHANGE",
  KODAMA_APP_CONTEXT_SWITCHER_MESSAGE: "KODAMA_APP_CONTEXT_SWITCHER_MESSAGE",
  KODAMA_APP_OPEN_CONTEXT_EDITOR_MESSAGE: "KODAMA_APP_OPEN_CONTEXT_EDITOR_MESSAGE"
}

config.persistQueryParams = [config.CONTEXT_VALUE];
config.isProdEnv = (["p1-spint"].includes(deployEnv));

Object.freeze(config.kodama);
Object.freeze(config);

export default config;
