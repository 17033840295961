import React from 'react';
import { Route } from 'react-router-dom';
import App from './App';
import LoadingSign from './LoadingSign';
import { getAppGroupConfig } from './clientService';
import { getApplicationGroup } from './utils';
import NotificationMessage from './NotificationMessage';
import ErrorMsg from './ErrorMsg';
import DarkModeWatcher from './darkModeWatcher';

class Dispatcher extends React.Component {
   constructor(props){
      super(props);
      this.state = {
        isLoading: true,
        oktaSignIn: null,
        appGroupConfig: null,
        isError: false,
        errorMsg: ''
      };
      this.notificationRef = null;
      this.setNotificationRef = this.setNotificationRef.bind(this);
      this.sendNotification = this.sendNotification.bind(this);
   }
   setNotificationRef(ref){
      this.notificationRef = ref;
   }
   sendNotification(notifyObj){
      this.notificationRef.notify(notifyObj);
   }
   componentDidMount(){
      let applicationGroup;
      DarkModeWatcher.setUp();
      try {
         applicationGroup = getApplicationGroup();
      } catch (e) {
         console.error(e);
         const message = 'Application Group not detected';
         this.setState({isLoading: false, isError: true, errorMsg: message }, () =>{
         this.sendNotification({
               type: "error",
               message
            });
         });
         return false;
      }
      getAppGroupConfig(applicationGroup)
         .then((appGroupConfig) => {

            /* modify document title if app group has name */
            if(appGroupConfig.name){
               document.title = `${appGroupConfig.name} | ${document.title}`;
            }
            this.setState({isLoading: false, appGroupConfig: appGroupConfig, isError: false, errorMsg: ''});
         }).catch((message) => {
            this.setState({isLoading: false, isError: true, errorMsg: message }, () => {
            const errorMsg = `Could not find Application Group ${applicationGroup}`;
            this.sendNotification({
               type: "error",
               message: errorMsg
            });
         });
      });
   }
   componentWillUnmount() {
     DarkModeWatcher.tearDown();
   }
   render(){
      const app = <Route path="/:applicationGroup" render={props => (
         <App appGroupConfig={this.state.appGroupConfig} {...props} sendNotification={this.sendNotification} />
      )} />;
      return (
         <React.Fragment>
            <NotificationMessage ref={this.setNotificationRef} />
            {this.state.isLoading ?
               <LoadingSign /> :
               (this.state.isError ? <ErrorMsg message={this.state.errorMsg} /> : app)
            }
         </React.Fragment>
      );
   }
}

export default Dispatcher;
